import React, { Component } from 'react';

export class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    initMap() {
        console.log("InitMAP");
        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng(this.props.config.coords.split(',')[0], this.props.config.coords.split(',')[1]);

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 16,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "greedy",
        });


        var marker = new window.google.maps.Marker({
            position: latLng,
            map: map,
        });


        this.setState({ googleMap: map });
    }

    componentDidMount() {

        if (this.props._googleMapsLoaded && !this.state._mapInit && this.props.config) {
            this.initMap();
        }

    }

    componentDidUpdate(prevProps) {

        if (this.props._googleMapsLoaded && !this.state._mapInit && this.props.config) {
            this.initMap();
        }
    }


    render() {
        return (
            this.props._googleMapsLoaded ?
                <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                </div>
                : null
        )
    }
}

export default Map;