

import React, { Component } from 'react';
import Routes from './routes'
import socketIO from 'socket.io-client';

class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    
    window.googleMapsCallback = this.googleMapsCallback;
    this.state = {

    }
  }

  googleMapsCallback(){
    this.setState({
      _googleMapsLoaded: true
    });
  }


  componentDidMount() {
    fetch('https://showroom-api.novamedia.agency/home').then((res) => res.json()).then((result) => { console.log(result); this.setState(result); } )
  }

  render() {

    return (
      <Routes
        {...this.state}
      />

    );

  }

}

export default App;
