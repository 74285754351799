import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';
import ReactPaginate from 'react-paginate';

import FilterForm from '../components/forms/filterForm';
import PageHeader from '../containers/header/pageHeader';
import Footer from '../containers/footer';

import Map from '../components/map';

import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselControl,
    CarouselItem,

} from 'reactstrap';


import fuel_icon from '../assets/svg/fuel.svg';
import calendar_icon from '../assets/svg/calendar.svg';
import guage_icon from '../assets/svg/guage.svg';
import color_icon from '../assets/svg/color.svg';
import engine_icon from '../assets/svg/engine.svg';
import door_icon from '../assets/svg/car-door.svg';
import transmission_icon from '../assets/svg/transmission.svg';
import car_icon from '../assets/svg/car-icon.svg';

import solution1 from '../assets/images/solution1.png';
import solution2 from '../assets/images/solution2.png';
import solution3 from '../assets/images/solution3.png';
import gallery1 from '../assets/images/gallery1.png';

const items = [
    {
        id: 1,
        text: '"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum."',
    },
    {
        id: 2,
        text: '"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum."',
    },
    {
        id: 3,
        text: '"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum."',
    }
];


class SolutionsPage extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            activeIndex: 0,
            date: new Date()
        };
    }


    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    componentDidMount(){
        fetch('https://showroom-api.novamedia.agency/services').then((res) => res.json()).then((services) => { console.log(services); this.setState({ services }); })

    }

    render() {
        const { activeIndex } = this.state;

        const slides = items.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item.id}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <Col md={{ size: 10, offset: 1 }}>
                        <p className="slide-content">{item.text}</p>
                    </Col>

                </CarouselItem>
            );
        });


        return (

            <div className={this.props.menu ? "solutions-wrap active-menu-animation" : "solutions-wrap"}>

                <PageHeader page='Service' {...this.props}/>
                <div className="page-wrap">

                    <section className="section solutions-section">
                        <Container >
                            <Row>
                                <Col md="12">
                                    <div className="sub-title text-center">
                                        <h2>{this.props.config && this.props.config.serviceTitle}</h2>
                                        <p>{this.props.config && this.props.config.serviceSubtitle}</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                {
                                    this.state.services && this.state.services.map((item, idx) => {
                                        return (
                                            <Col md="4">
                                            <article>
                                                <div>
                                                    <h3>{item.name} <span>{item.name1}</span></h3>
                                                    <p>{item.shortDescription}</p>
                                                    
                                                </div>
                                                <img src={item.image} />
                                            </article>
                                        </Col>
        
                                        )
                                    })
                                }
                            </Row>
                        </Container>
                    </section>
{/*
                    <section className="section achivments-section">
                        <Container >
                            <Row>
                                <Col md="12">
                                    <div className="sub-title text-center">
                                        <h2>SOLUTIONS WE PROVIDE</h2>
                                        <p>Nam non nisl. Phasellus commodo libero ac massa. </p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="items">
                                <Col md="3">
                                    <Isvg src={car_icon} />
                                    <div className="text">
                                        <span>242.438</span>
                                        <span>CARS IN GARAGE</span>
                                    </div>
                                </Col>
                                <Col md="3">
                                    <Isvg src={calendar_icon} />
                                    <div className="text">
                                        <span>242.438</span>
                                        <span>CARS SOLD</span>
                                    </div>
                                </Col>
                                <Col md="3">
                                    <Isvg src={engine_icon} />
                                    <div className="text">
                                        <span>242.438</span>
                                        <span>CARS SERVICED</span>
                                    </div>
                                </Col>
                                <Col md="3">
                                    <Isvg src={guage_icon} />
                                    <div className="text">
                                        <span>242.438</span>
                                        <span>TOTAL KILOMETER</span>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
</section>*/}

                                   {this.props.infoblock && this.props.infoblock.length == 4 ?
                    <section className="section services-section">

                        <Container>
                            <Row>
                                <Col md="12">
                                    <div className="sub-title text-center">
                                    <h2>{this.props.config && this.props.config.infoBlockTitle}</h2>
                                    </div>
                                </Col>

                                <Col md="3" xs="6">
                                    <article>
                                        <img src={this.props.infoblock[0].image} />
                                        <h3>{this.props.infoblock[0].name}</h3>
                                        <p>{this.props.infoblock[0].content}</p>
                                        <Link to={this.props.infoblock[0].link} className="button"></Link>
                                    </article>
                                </Col>

                                <Col md="3" xs="6">
                                    <article>
                                        <img src={this.props.infoblock[1].image} />
                                        <h3>{this.props.infoblock[1].name}</h3>
                                        <p>{this.props.infoblock[1].content}</p>
                                        <Link to={this.props.infoblock[1].link} className="button"></Link>
                                    </article>
                                </Col>

                                <Col md="3" xs="6">
                                    <article>
                                        <img src={this.props.infoblock[2].image} />
                                        <h3>{this.props.infoblock[2].name}</h3>
                                        <p>{this.props.infoblock[2].content}</p>
                                        <Link to={this.props.infoblock[2].link} className="button"></Link>
                                    </article>
                                </Col>

                                <Col md="3" xs="6">
                                    <article>
                                        <img src={this.props.infoblock[3].image} />
                                        <h3>{this.props.infoblock[3].name}</h3>
                                        <p>{this.props.infoblock[3].content}</p>
                                        <Link to={this.props.infoblock[3].link} className="button"></Link>
                                    </article>
                                </Col>

                            </Row>
                        </Container>
                    </section>
                    :
                    null
                }


{/*
                    <section className="section reviews-section">
                        <Container>

                            <Row>
                                <Col md="12">
                                    <div className="sub-title text-center">
                                        <h2>WHAT THEY SAY ABOUT US</h2>
                                        <p>Latest testimonials</p>
                                    </div>

                                </Col>

                                <Carousel
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                    autoPlay={false}
                                >
                                    {slides}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                </Carousel>

                                <Col md={{ offset: 4, size: 1 }} className="prev-review">
                                    <img src={gallery1} />
                                </Col>

                                <Col md={{ size: 2 }} className="curr-review">
                                    <img src={gallery1} />
                                    <div className="name">Mariana Garcia</div>
                                    <div className="type">Satisfied Buyer</div>
                                </Col>
                                <Col md={{ size: 1 }} className="next-review">
                                    <img src={gallery1} />
                                </Col>


                            </Row>
                        </Container>
                    </section>
*/}
                    <section className="section map-section">
                        <Container fluid>

                            <Row>
                                <Col md="12">
                                    <Map {...this.props} />
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <Footer {...this.props} />

                </div>



            </div >
        );
    }
}

const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(SolutionsPage));
